var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var _boundTickInternal, _looperId;
import CallbackLooper from '@root/javascript/common/callback-looper';
import zpad from '@root/javascript/util/text/zpad';
const MainLooper = CallbackLooper.main;
export default class {
    constructor() {
        _boundTickInternal.set(this, this.tickInternal.bind(this));
        _looperId.set(this, null);
        this.zpad = (value) => zpad(value);
        this.zpadIf = (value, condition) => (condition ? zpad(value) : value);
    }
    stop() {
        if (this.isRunning) {
            MainLooper.remove(__classPrivateFieldGet(this, _looperId, "f"));
            __classPrivateFieldSet(this, _looperId, null, "f");
        }
    }
    start() {
        if (!this.isRunning) {
            this.tick(Date.now());
            __classPrivateFieldSet(this, _looperId, MainLooper.add(__classPrivateFieldGet(this, _boundTickInternal, "f")), "f");
        }
    }
    get isRunning() {
        return __classPrivateFieldGet(this, _looperId, "f") !== null;
    }
    tickInternal(_timestamp, now) {
        this.tick(now);
    }
}
_boundTickInternal = new WeakMap(), _looperId = new WeakMap();
