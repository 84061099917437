const BASE_COOKIE_PATH = '/';
const DEFAULT_SECURE_FLAG = window.location.protocol === 'https:';
const ONE_YEAR = 31536000;
class CookieUtils {
    static delete(name, path = BASE_COOKIE_PATH) {
        this.set(name, '', { expiresInSeconds: 0, path });
    }
    static get(name, defaultValue, { parseAsJson = true, } = {}) {
        const suffixedName = `${name}=`;
        const cookies = document.cookie.split(/;\s*/);
        const cookieCount = cookies.length;
        let value = defaultValue;
        for (let i = 0; i < cookieCount; i += 1) {
            if (cookies[i].startsWith(suffixedName)) {
                // The #replace below is an ugly fix for incompatibilities between JS and Rails
                value = decodeURIComponent(cookies[i].substring(suffixedName.length).replace(/\+/g, '%20'));
                if (parseAsJson) {
                    try {
                        value = JSON.parse(value);
                    }
                    catch (e) {
                        // do nothing
                    }
                }
            }
        }
        return value;
    }
    static getTyped(name, defaultValue) {
        const value = this.get(name, defaultValue);
        if (typeof value === 'object') {
            return value;
        }
        return defaultValue;
    }
    static extend(name, options) {
        const invalid = '__non_existent__';
        const value = this.get(name, invalid, { parseAsJson: false });
        if (value !== invalid) {
            this.set(name, value, options);
            return true;
        }
        return false;
    }
    /**
     * Save a cookie to the user's browser.
     * @param {String} name
     * @param {any} value
     */
    static set(name, value, { path = BASE_COOKIE_PATH, expiresInSeconds = ONE_YEAR, secure = DEFAULT_SECURE_FLAG, domain = null, sameSite = null, } = {}) {
        let encodedValue = value;
        if (typeof encodedValue === 'object') {
            encodedValue = JSON.stringify(encodedValue);
        }
        const parts = [`${name}=${encodeURIComponent(encodedValue)}`];
        // Secure flag
        if (secure === true) {
            parts.push('secure');
        }
        // Expiration
        if (expiresInSeconds) {
            const exdate = new Date();
            exdate.setTime(exdate.getTime() + (expiresInSeconds * 1000));
            parts.push(`expires=${exdate.toUTCString()}`);
            parts.push(`max-age=${expiresInSeconds}`);
        }
        // Path
        if (path) {
            parts.push(`path=${path}`);
        }
        // Domain
        if (domain) {
            parts.push(`domain=${domain}`);
        }
        // Same-site
        if (sameSite) {
            parts.push(`samesite=${sameSite}`);
        }
        document.cookie = parts.join('; ');
        return true;
    }
}
CookieUtils.BASE_COOKIE_PATH = BASE_COOKIE_PATH;
CookieUtils.ONE_YEAR = ONE_YEAR;
CookieUtils.EXPIRES_IN_PERMANENT = ONE_YEAR * 20;
export default CookieUtils;
export const getCookie = CookieUtils.get;
export const setCookie = CookieUtils.set;
