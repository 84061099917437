var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _key, _enabled;
import CookieUtils from '@utils/cookie-utils';
const HIDE_UNAVAILABLE_KEY = 'hide_unavailable';
const DEFAULT_HIDE_UNAVAILABLE = true;
export default class {
    constructor(namespace, { enabled, enabledByDefault, } = {
        enabledByDefault: DEFAULT_HIDE_UNAVAILABLE,
    }) {
        _key.set(this, void 0);
        _enabled.set(this, void 0);
        __classPrivateFieldSet(this, _key, `${HIDE_UNAVAILABLE_KEY}__${namespace}`, "f");
        __classPrivateFieldSet(this, _enabled, enabled !== null && enabled !== void 0 ? enabled : CookieUtils.get(__classPrivateFieldGet(this, _key, "f"), enabledByDefault, {
            parseAsJson: true,
        }), "f");
    }
    get enabled() {
        return __classPrivateFieldGet(this, _enabled, "f");
    }
    set enabled(value) {
        __classPrivateFieldSet(this, _enabled, value, "f");
        CookieUtils.set(__classPrivateFieldGet(this, _key, "f"), value);
    }
    toggle() {
        this.enabled = !this.enabled;
    }
}
_key = new WeakMap(), _enabled = new WeakMap();
