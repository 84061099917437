var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _delta;
// Note: '| 0' truncates decimals so that only an integer is returned
export default class {
    constructor(initialSecondsDelta) {
        _delta.set(this, 0);
        __classPrivateFieldSet(this, _delta, initialSecondsDelta, "f");
    }
    set secondsDelta(newDelta) {
        __classPrivateFieldSet(this, _delta, newDelta, "f");
    }
    get days() {
        return __classPrivateFieldGet(this, _delta, "f") / 86400 | 0;
    }
    get hours() {
        return (__classPrivateFieldGet(this, _delta, "f") / 3600) % 24 | 0;
    }
    get minutes() {
        return (__classPrivateFieldGet(this, _delta, "f") / 60) % 60 | 0;
    }
    get seconds() {
        return __classPrivateFieldGet(this, _delta, "f") % 60 | 0;
    }
    valueOf() {
        return __classPrivateFieldGet(this, _delta, "f");
    }
}
_delta = new WeakMap();
