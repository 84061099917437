var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _isOpen;
import { Controller } from '@local-stimulus';
const ACTIVE_CLASS = 'is-active';
export default class extends Controller {
    constructor() {
        super(...arguments);
        _isOpen.set(this, void 0);
    }
    connect() {
        __classPrivateFieldSet(this, _isOpen, this.element.classList.contains(ACTIVE_CLASS), "f");
    }
    softClose() {
        __classPrivateFieldSet(this, _isOpen, false, "f");
        this.element.classList.remove(ACTIVE_CLASS);
    }
    close() {
        this.softClose();
        this.element.remove();
    }
    open() {
        __classPrivateFieldSet(this, _isOpen, true, "f");
        this.element.classList.add(ACTIVE_CLASS);
    }
    get isOpen() {
        return __classPrivateFieldGet(this, _isOpen, "f");
    }
}
_isOpen = new WeakMap();
