var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _nodes;
import fastdom from 'fastdom';
import TimeDiff from '@root/javascript/common/time-diff';
import currentTimeInSeconds from 'javascript/time/current-time-in-seconds';
import AbstractCountdownRunner from './abstract-countdown-runner';
export default class extends AbstractCountdownRunner {
    constructor() {
        super(...arguments);
        _nodes.set(this, new Map());
    }
    addElement(element, label) {
        const { dataset } = element;
        const node = document.createTextNode('');
        const timestamp = +dataset.timestamp;
        if (label) {
            element.replaceChildren(document.createTextNode(`${label}: `), node);
        }
        else {
            element.replaceChildren(node);
        }
        this.add(node, timestamp);
        return { node, timestamp };
    }
    addEntry(entry) {
        this.add(entry.node, entry.timestamp);
    }
    add(node, timestamp) {
        this.internalTickItem(node, timestamp);
        if (timestamp < currentTimeInSeconds()) {
            return;
        }
        __classPrivateFieldGet(this, _nodes, "f").set(node, timestamp);
        if (!this.isRunning) {
            this.start();
        }
    }
    remove(item) {
        let node = item;
        if (Object.prototype.hasOwnProperty.call(item, 'node')) {
            node = item.node;
        }
        if (__classPrivateFieldGet(this, _nodes, "f").has(node)) {
            __classPrivateFieldGet(this, _nodes, "f").delete(node);
            if (__classPrivateFieldGet(this, _nodes, "f").size < 1) {
                this.stop();
            }
        }
    }
    tick() {
        fastdom.mutate(() => {
            const now = currentTimeInSeconds();
            __classPrivateFieldGet(this, _nodes, "f").forEach((timestamp, node) => {
                this.internalTickItem(node, timestamp, now);
            });
        });
    }
    internalTickItem(node, timestamp = __classPrivateFieldGet(this, _nodes, "f").get(node), now = currentTimeInSeconds()) {
        this.tickItem(node, new TimeDiff(timestamp - now));
    }
    tickItem(node, diff) {
        if (diff.valueOf() > 0) {
            this.updateItem(node, diff);
        }
        else {
            this.updateFinishedItem(node);
        }
    }
    computeValue(_node, diff) {
        return `${diff.days}d ${this.zpad(diff.hours)}h ${this.zpad(diff.minutes)}m ${this.zpad(diff.seconds)}s`;
    }
    updateItem(node, diff) {
        const newValue = this.computeValue(node, diff);
        if (node.nodeValue !== newValue) {
            node.nodeValue = newValue;
        }
    }
    updateFinishedItem(node) {
        node.nodeValue = '0d 0h 0m 0s';
        this.remove(node);
    }
}
_nodes = new WeakMap();
